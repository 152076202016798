<template>
    <header class="header">
        <router-link :to="{name: ROUTES.GAME_VIEW.name}" class="header-logo">
            <img class="logo" src="/4444logo.png" alt="4444">
        </router-link>
        <nav class="header-nav">
            <ul class="header-nav-list">
                <li><router-link :to="{name: ROUTES.GAME_VIEW.name}" class="header-nav-link active">Грати</router-link></li>
                <li><a href="https://4444.ua/pro-nas/" class="header-nav-link">Про нас</a></li>
                <li><a href="https://4444.ua/ihry/" class="header-nav-link">Ігри</a></li>
                <li><a href="https://4444.ua/nashi-zaly/" class="header-nav-link">Наші Зали</a></li>
                <li><a href="https://4444.ua/hravtsiam/" class="header-nav-link">Гравцям</a></li>
                <li><a href="https://4444.ua/dlia-biznesu/" class="header-nav-link">Для Бізнесу</a></li>
                <li><a href="https://4444.ua/contacts/" class="header-nav-link">Контакти</a></li>
            </ul>
        </nav>
        <div class="header-actions">
            <button class="btn" @click="$router.push({name: ROUTES.DEPOSIT_VIEW.name})">{{$t("header.deposit")}}</button>
            <div class="balance" v-if="balance" data-testid="balance">
                {{balance}}
            </div>
            <div class="logged" :class="{active: showMenu}">
                <div class="menu-overlay" v-if="showMenu" @click="showMenu = false"></div>
                <a href="#" @click.prevent="showMenu = !showMenu" class="btn-logged" data-testid="menu-link">
                    <img src="/icon-person.png" alt="">
                    <div data-testid="user-number" class="name">{{userNumber}}</div>
                </a>
                <transition name="fade" mode="out-in">
                    <div v-if="showMenu" class="logged-menu">
                        <router-link :to="{name: ROUTES.PROFILE.name}" class="logged-menu-item">
                            <div class="name">Персональні дані</div>
                        </router-link>
                        <router-link :to="{name: ROUTES.DEPOSIT_VIEW.name}" class="logged-menu-item">
                            <div class="name">{{$t("header.refill")}}</div>
                        </router-link>
                        <router-link :to="{name: ROUTES.WITHDRAWAL_VIEW.name}" class="logged-menu-item" data-testid="withdrawal-menu-item">
                            <div class="name">{{$t("header.withdrawal")}}</div>
                        </router-link>
                        <router-link :to="{name: ROUTES.WITHDRAWAL_ORDERS.name}" class="logged-menu-item">
                            <div class="name">{{$t("header.finances")}}</div>
                        </router-link>
                        <div class="logged-menu-item">
                            <div class="name" @click="logout">Закрити сесію</div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="hamburger" @click="mobMenuShow = !mobMenuShow">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
        </div>
        <Transition name="slide-fade" mode="out-in">
            <div v-if="mobMenuShow" class="mob-menu">
                <div class="overlay" @click="mobMenuShow = false"></div>
                <div class="mob-menu-container">
                    <div class="user">
                        <img src="/icon-person.png" alt="">
                        <div data-testid="mobile-user-number" class="name">{{userNumber}}</div>
                    </div>
                    <ul>
                        <li><router-link :to="{name: ROUTES.GAME_VIEW.name}" class="header-nav-link active">Головна</router-link></li>
                        <li><a href="https://4444.ua/pro-nas/" class="header-nav-link">Про нас</a></li>
                        <li><a href="https://4444.ua/ihry/" class="header-nav-link">Ігри</a></li>
                        <li><a href="https://4444.ua/nashi-zaly/" class="header-nav-link">Наші Зали</a></li>
                        <li><a href="https://4444.ua/hravtsiam/" class="header-nav-link">Гравцям</a></li>
                        <li><a href="https://4444.ua/dlia-biznesu/" class="header-nav-link">Для Бізнесу</a></li>
                        <li><a href="https://4444.ua/contacts/" class="header-nav-link">Контакти</a></li>
                    </ul>
                    <div class="logged-menu">
                        <router-link :to="{name: ROUTES.PROFILE.name}" class="logged-menu-item">
                            <div class="name">Персональні дані</div>
                        </router-link>
                        <router-link :to="{name: ROUTES.DEPOSIT_VIEW.name}" class="logged-menu-item">
                            <div class="name">{{$t("header.refill")}}</div>
                        </router-link>
                        <router-link :to="{name: ROUTES.WITHDRAWAL_VIEW.name}" class="logged-menu-item">
                            <div class="name">{{$t("header.withdrawal")}}</div>
                        </router-link>
                        <router-link :to="{name: ROUTES.WITHDRAWAL_ORDERS.name}" class="logged-menu-item">
                            <div class="name">{{$t("header.finances")}}</div>
                        </router-link>
                        <div class="logged-menu-item">
                            <div class="name" @click="logout">Закрити сесію</div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </header>
</template>

<script lang="ts" src="./header-main-component.ts"/>
