export const ROUTES = {
    HOME: {
        name: "home",
        path: "/home"
    },
    LOGIN: {
        name: "login",
        path: "/login"
    },
    REGISTRATION: {
        name: "registration",
        path: "/registration"
    },
    GAME_VIEW: {
        name: "game",
        path: "/game"
    },
    DEPOSIT_VIEW: {
        name: "deposit",
        path: "/deposit"
    },
    WITHDRAWAL_VIEW: {
        name: "withdrawal",
        path: "/withdrawal"
    },
    PROFILE: {
        name: "profile",
        path: "/profile"
    },
    CODECONFIRM: {
        name: "codeConfirm",
        path: "/confirm"
    },
    WITHDRAWAL_ORDERS: {
        name: "withdrawal-orders",
        path: "/finances/withdrawal-orders"
    },
    DEPOSIT_ORDERS: {
        name: "deposit-orders",
        path: "/finances/deposit-orders"
    }
};
