/* eslint quote-props: ["error", "always"] */
export default {
    "brand": {
        "name": "ЧЕТВІРКИ НАЖИВО",
        "description1": "Насолоджуйтесь грою на реальному гральному автоматі.",
        "description2": "Десятки улюблених ігор, унікальний ігровий досвід, справжні враження."
    },
    "tempPassword": "Змініть тимчасовий пароль",
    "common": {
        "forms": {
            "validation": {
                "required": "Поле повинно бути заповненим",
                "email": "Вказана пошта не є корректню",
                "min": "Мінімальна довжина поля {param} символів",
                "confirmed": "Паролі не співпадають",
                "invalidFormat": "Невірний формат",
                "birthday": "Вам має бути 18+ років",
                "invalidCharacters": "Символи + - , ; & є неприпустимими",
                "digits": "Потрібно ввести {param} цифр"
            },
            "closeButton": "Закрити",
            "yes": "Так",
            "no": "Ні",
            "ok": "Ок",
            "cancel": "Відміна",
            "close": "Закрити",
            "open": "Відкрити",
            "continue": "Продовжити",
            "clear": "Очистити",
            "loadMore": "Більше"
        }
    },
    "operations": {
        "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH": "Поповнення ігрового рахунку",
        "PAY_OPERATION_WITHDRAWAL_CASH": "Зняття з ігрового рахунку",
        "PAY_OPERATION_VOUCHER_GENERATION_CASH": "Продаж ваучера",
        "PAY_OPERATION_VOUCHER_REDEMPTION_CASH": "Купівля ваучера",
        "PAY_OPERATION_VOUCHER_REDEMPTION_TRM": "Купівля ваучера",
        "PAY_OPERATION_VOUCHER_GENERATION_BP": "Продаж ваучера на Betplace",
        "PAY_OPERATION_INCREASE_BALANCE_BP": "Поповнення балансу Betplace",
        "PAY_OPERATION_WINPAID": "Виплата виграшу",
        "PAY_OPERATION_FOR_RACE_WINPAID_BP": "Виплата за карткою Перегони BP",
        "PAY_OPERATION_FOR_RACE_WINPAID_CASH": "Виплата за карткою Перегони",
        "PAY_OPERATION_NULLIFICATION_POINT_BALANCE": "Обнуління балансу Betplace",
        "PAY_OPERATION_REFERRAL_ACCOUNT_DEPOSIT": "Партнерське поповнення",
        "PAY_OPERATION_REFERRAL_ACCOUNT_WITHDRAWA": "Виведення коштів через партнера",
        "PAY_OPERATION_INCREASE_ACCOUNT": "Поповнення рахунку",
        "PAY_OPERATION_WITHDRAWAL": "Зняття з рахунку ",
        "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_ACCOUNT": "Пряме поповнення",
        "PAY_OPERATION_DEPOSIT_BONUS_REFILL": "Бонус",
        "PAY_OPERATION_INCREASE_BONUS_ACCOUNT": "Поповнення бонусного рахунку (Спорт)",
        "PAY_OPERATION_INCREASE_BONUS_CASINO_ACCOUNT": "Поповнення бонусного рахунку (Казино)",
        "PAY_OPERATION_CANCEL_BONUS_ACCOUNT": "Скасування бонусу (Cпорт)",
        "PAY_OPERATION_CANCEL_BONUS_CASINO_ACCOUNT": "Скасування бонусу (Казино)",
        "PAY_OPERATION_CANCEL_STAKE_BP": "Скасування ставки Betplace",
        "PAY_OPERATION_WITHDRAWAL_BONUS_ACCOUNT": "Зарахування з бонусного рахунку (Спорт)",
        "PAY_OPERATION_WITHDRAWAL_CASINO_BONUS_ACCOUNT": "Зарахування з бонусного рахунку (Казино)",
        "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL": "Переказ на основний рахунок",
        "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT": "Переказ на ігровий рахунок",
        "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL_BP": "Переказ на ігровий рахунок",
        "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP": "Переказ на ігровий рахунок",
        "PAY_OPERATION_FOR_RACE_WINPAID": "Виграш на Забігах",
        "PAY_OPERATION_FOR_RACE_CARD": "Ставка на Забіги"
    },
    "webdesktop": {
        "withdrawalOrders": {
            "dateColumn": "Дата",
            "amount": "Сума",
            "method": "Тип зняття",
            "status": "Статус",
            "cancel": "Скасування",
            "payCode": "Код",
            "wt": {
                "WITHDRAWAL_METHOD_VOUCHER": "Ваучер",
                "WITHDRAWAL_METHOD_VOUCHER_INSTANT": "Ваучер експрес",
                "WITHDRAWAL_METHOD_VISA": "Visa",
                "WITHDRAWAL_METHOD_LUKITA": "Lukita",
                "WITHDRAWAL_METHOD_YAPE": "Yape",
                "WITHDRAWAL_METHOD_TUNKI": "Tunki"
            },
            "statusText": {
                "ORDER_WITHDRAWAL_STATUS_WAITING_CONFIRMATION": "Потрібно підтвердити заявку за допомогою секретного коду протягом 24 годин",
                "ORDER_WITHDRAWAL_STATUS_CONFIRMATION_EXPIRED": "Заявка на виплату не була підтверджена користувачем протягом 24 годин",
                "ORDER_WITHDRAWAL_STATUS_WAITING_APPROVE": "Заявка на виплату очікує на розгляд",
                "ORDER_WITHDRAWAL_STATUS_APPROVED": "Заявка схвалена адміністрацією і готується до виплати",
                "ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED": "Заявка схвалена і буде передана на виплату після підтвердження особи. Потрібно надати документ, що засвідчує особу. зверніться до підтримки для отримання детальної інформації",
                "ORDER_WITHDRAWAL_STATUS_PAID": "Заявка передана на оплату",
                "ORDER_WITHDRAWAL_STATUS_CASH_WAITING": "Заявка очікує виплати клієнту на касі протягом 3 діб.",
                "ORDER_WITHDRAWAL_STATUS_CASH_PAID": "Заявку виплачено на касі",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_EXPIRED": "Виплата не була отримана клієнтом протягом 3 діб і була анульована",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_ERROR": "Виплата неможлива. Для отримання детальної інформації зверніться до підтримки",
                "ORDER_WITHDRAWAL_STATUS_DENIED": "В виплаті відмовлено. Заявка анульована. Для отримання детальної інформації зверніться до підтримки",
                "ORDER_WITHDRAWAL_STATUS_USER_CANCELLED": "Заявку на виплату було скасовано кліентом",
                "ORDER_WITHDRAWAL_STATUS_PAYSYSTEM_PAID": "Заявку виплачено"
            },
            "confirm": {
                "caption": "Скасування платіжної операції",
                "text": "Ви дійсно хочете скасувати платіжну операцію?"
            },
            "cancelSuccess": "Платіжну операцію було успішно скасовано"
        },
        "deposit": {
            "voucher": {
                "success": "Попвнення на суму {amount} виконано успішно.",
                "found": "Талон №{code} знайдено, на суму {amount}",
                "numberLabel": "Введіть код талону:",
                "caption": "Ваучер iPay"
            },
            "amountInput": {
                "caption": "Поповнення рахунку {type}",
                "amount": "Сума: ",
                "currency": "Валюта: ",
                "dialogText": "Перевірте дані та підтвердіть операцію: вы запросили платіж на суму {amount}{currency}",
                "min": "Мінімальна сума поповнення сладає {amount}{currency}."
            }
        },
        "changePasswordDialog": {
            "caption": "Зміна пароля",
            "currentPasswordLabel": "Поточний пароль",
            "newPasswordLabel": "Новий пароль",
            "newPasswordConfirmLabel": "Підтвердити новий пароль",
            "submitButton": "Змінити пароль",
            "successMessage": "Пароль успішно змінено"
        },
        "withdrawalConfirm": {
            "title": "Платіжний запит №",
            "total": "Всього до оплати: ",
            "withdrawalType": "Тип виплати: ",
            "payCode": "Код виплати: ",
            "expiration": "Оплата цього запиту може бути здійснена до: "
        },
        "confirmCodeDialog": {
            "success": "На вашу контактну адресу, вказану при реєстрації, відправлено новий код!",
            "caption": "Потрібно підтвердити операцію!",
            "text": "Введіть код, надісланий на ваш телефон, вказаний при реєстрації, в наступне поле:",
            "inputLabel": "Код підтвердження",
            "submitButton": "Перевірити"
        },
        "withdrawal": {
            "general": {
                "phoneNumber": "Номер телефону {phoneNumber} буде використаний для зняття"
            },
            "partner": {
                "caption": "Виведення коштів з рахунку",
                "confirmationMethod": "Виберіть спосіб підтвердження:",
                "confirmationEmail": "Электронна пошта",
                "confirmationPhone": "Телефон"
            },
            "minAmountError": "Мінімальна сума зняття {minAmount}",
            "lowBalance": "Сума зняття більше доступної"
        }
    },
    "registration": {
        "title": "Реєстрація",
        "registrationButton": "Реєстрація",
        "loginButton": "Увійти",
        "login": "Телефон",
        "loginNumber": "Номер рахунку",
        "password": "Пароль",
        "success": "Вітаємо з успішною реєстрацією!",
        "ageAgreement": "Підтверджую, що досяг 21-річного віку і не перебуваю\n" +
            "                                        в Реєстрі осіб, яким обмежено доступ до гральних\n" +
            "                                        закладів та/або участь в азартних іграх",
        "rulesAgreement": "Ознайомлений і приймаю умови <a href=\"https://4444.ua/pravova-informatsiia\" target='_blank'>Правил</a><br/>\n" +
            "                                        участі в азартних іграх",
        "dataAgreement": "Даю згоду на обробку моїх персональних даних"
    },
    "login": {
        "title": "Вхід",
        "login": "Телефон",
        "loginNumber": "Номер рахунку",
        "password": "Пароль",
        "forgotPassword": "Забули пароль?",
        "submitButton": "Увійти",
        "registrationButton": "Реєстрація",
        "playButton": "Грати",
        "detailsButton": "Детальніше",
        "detailsTitle": "АЗАРТНА ГРА НА ВІДСТАНІ ДЕКІЛЬКОХ КЛІКІВ",
        "details": "Бажаєте відчути атмосферу гри на автоматі не тільки в залі? ЧЕТВІРКИ НАЖИВО до\n" +
            "        ваших послуг.<br/>\n" +
            "        <br/>\n" +
            "        Вперше в Україні та лише в ЧЕТВІРКАХ гра на реальному автоматі із будь-якого місця у\n" +
            "        світі.<br/>\n" +
            "        <br/>\n" +
            "        Реєструйтесь, обирайте свій улюблений автомат, і починайте грати. А якщо вже\n" +
            "        зареєстровані, то увійдіть на сайт і нумо до гри.<br/>\n" +
            "        <br/>\n" +
            "        Урахована кожна дрібниця, щоб Ви відчували себе максимально комфортно.<br/>\n" +
            "        <br/>\n" +
            "        Тож скоріше долучайтесь і грайте скрізь, де б ви не були, разом із ЧЕТВІРКАМИ\n" +
            "        НАЖИВО!"
    },
    "resetPasswordDialog": {
        "caption": "Відновити пароль",
        "emailTabCaption": "По e-mail",
        "phoneTabCaption": "По телефону",
        "submitButton": "Створити тимчасовий пароль",
        "emailPlaceholder": "Введіть свій e-mail",
        "emailTabText1": "Для відновлення пароля введіть адресу електронної пошти, вказану вами під час реєстрації.",
        "emailTabText2": "Ми надішлемо вам електронного листа з подальшими інструкціями.",
        "phoneTabText1": "Для відновлення пароля введіть номер телефону, який ви вказали під час реєстрації.",
        "phoneTabText2": "Ми надішлемо вам SMS із подальшими інструкціями.",
        "success": "Вам надіслано тимчасовий пароль",
        "phonePlaceholder": "Введіть свій номер телефону"
    },
    "header": {
        "deposit": "Депозит",
        "refill": "Поповнення",
        "withdrawal": "Зняття",
        "finances": "Фінанси"
    },
    "idle": {
        "message": "Сеанс завершено через неактивність."
    },
    "errors": {
        "mtl": {
            "code1115": "Користувач з таким номером вже існує",
            "code1118": "Невірно вказано номер телефону",
            "code1122": "Невірний логін або пароль",
            "code1133": "Акаунт заблоковано після 3 невірних спроб введення пароля. Для розблокування скористайтеся функцією відновлення пароля.",
            "code1123": "Користувач заблокований",
            "code1124": "Користувач не може виконувати вхід із зазначеного робочого місця",
            "code1125": "Відсутня валюта рахунку",
            "code1126": "У користувача кілька рахунків",
            "code1127": "Не співпадає валюта",
            "code1154": "Вказаний ID картки гравця вже використовується",
            "code1161": "Некоректий телефон",
            "code1162": "Користувач не активований",
            "code1172": "Некоректно вказано поточний пароль",
            "code1173": "Мінімальна довжина пароля 8 символів",
            "code1001": "Користувач не авторизований",
            "code2301": "Ваучер не знайдено",
            "code2302": "Ваучер вже використаний",
            "code2305": "Відсутні права на погашення ваучеру",
            "code2307": "Неспівпадіння партнерів",
            "code4316": "Неспівпадіння валют",
            "code4301": "Загальна помилка поповнення",
            "code4307": "Користувач заблокований",
            "code4310": "Неспівпадіння партнерів",
            "code5103": "Невірна сума",
            "code4451": "Додаток не має прав на проведення операції",
            "code4452": "Недостатньо прав для проведення операції",
            "code4453": "Не відкрито рахунок з вказаною валютою",
            "code4454": "Запитувана сума перевищує наявну",
            "code4455": "Не встановлено контакт для відправки підтверждення",
            "code4456": "Недостатньо прав для використання вказаного методу підтвердження операції",
            "code1051": "Код підтверждення не знайдено",
            "code1052": "Код підтверждення вже використаний",
            "code1053": "Можливий час на підтвердження операції закінчився",
            "code101": "Сеанс завершено через неактивність."
        }
    },
    "tabs": {
        "phone": "Телефон",
        "number": "Рахунок"
    },
    "profile": {
        "uploadSuccess": "Файл завантажено",
        "title1": "Дані облікового запису",
        "number": "Номер рахунку",
        "password": "Пароль",
        "date": "Дата реєстрації",
        "id": "ID картки гравця",
        "title2": "Персональні дані",
        "phone": "Номер телефону",
        "surname": "Прізвище",
        "name": "Ім’я",
        "patronymic": "По батькові",
        "birthday": "Дата народження",
        "doc": "Документ",
        "save": "Зберегти",
        "fileNotSelected": "Файл не вибрано",
        "success": "Дані успішно збережені",
        "titleDoc": "Завантаження документа",
        "docType": "Документ",
        "selectDocType": "Виберіть тип документа",
        "selectBtn": "Вибрати файл",
        "uploadBtn": "Завантажити",
        "file": "Файл",
        "docTypes": {
            "1": "Паспорт громадянина України (ID - картка)",
            "2": "Паспорт для виїзду за кордон",
            "3": "Посвідчення водія",
            "4": "Паспорт іноземної країни (ID-картка)",
            "5": "Індивідуальний податковий номер - ІПН"
        },
        "docStatus": {
            "CONTENT_STATUS_FILE_LOADED": "Очікує на розгляд",
            "CONTENT_STATUS_BAD_QUALITY": "Низька якість / Нечітка копія",
            "CONTENT_STATUS_TYPE_MISMATCH": "Невідповідний тип документа",
            "CONTENT_STATUS_INSUFFICIENT_DATA": "Недостатні дані",
            "CONTENT_STATUS_ADD_PROOF_NEEDED": "Додаткове підтвердження",
            "CONTENT_STATUS_PROFILE_DATA_MISMATCH": "Невідповідність даних",
            "CONTENT_STATUS_PERSON_DATA_ACCEPTED": "Прийнято",
            "CONTENT_STATUS_DOCUMENT_NOT_ACCEPTED": "Не прийнято"
        },
        "docNecessarily": "*Обов'язко будь-який документ, що підтверджує особу та ІПН."
    },
    "finances": {
        "deposit": "Поповнення",
        "withdrawal": "Зняття",
        "operationColumn": "Операція",
        "cols": {
            "date": "Дата, час",
            "amount": "Сума",
            "method": "Метод",
            "status": "Статус"

        }
    }
};
