<template>
    <router-view v-if="isInitialized"/>
    <message-box v-if="isMessageBoxActive" :title="caption" :description="message" :type="messageBoxType" @onClose="messageBoxClose" />
</template>

<script src="./app-component.ts"></script>
<style lang="scss">
  @import "@scss/app.scss";
  @import "@scss/auth.scss";
  @import "@scss/header.scss";
  @import "@scss/footer.scss";
  @import "@scss/modal.scss";
  @import "@scss/messagebox.scss";
  @import "@scss/transaction.scss";
  @import "@scss/profile.scss";
  @import "@scss/router-tabs.scss";
  @import "@scss/finances.scss";
</style>
